import { useConnectGoogleCalendarMutation } from '@/api/piloteer';
import calendarPermissionImage from '@/assets/gifs/calendar.gif';
import { Spinner } from '@/ui/@components/feedback/loading/spinner';
import { Button } from '@/ui/@components/primitives/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/ui/@components/primitives/dialog';
import { APP_ROUTES } from '@/ui/application-routes';
import { useToast } from '@/ui/hooks/use-toast';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const GoogleCalendarOAuthCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const isConnecting = useRef(false);
  const mounted = useRef(true);
  const [showPermissionDialog, setShowPermissionDialog] = useState(false);

  const [connectGoogleCalendar] = useConnectGoogleCalendarMutation();

  const handleTryAgain = () => {
    setShowPermissionDialog(false);
    navigate(APP_ROUTES.SETTINGS.INTEGRATIONS, {
      state: {
        googleCalendarConnectError: true,
        errorDetails: 'Calendar access permission required',
      },
      replace: true,
    });
  };

  const handleConnectGoogleCalendar = useCallback(
    async (authorizationCode: string) => {
      if (isConnecting.current || !mounted.current) return;
      isConnecting.current = true;

      // Check if scope parameter exists and includes calendar access
      const scope = searchParams.get('scope');
      if (
        !scope ||
        !scope.includes('https://www.googleapis.com/auth/calendar')
      ) {
        console.error('Calendar permission not granted');
        setShowPermissionDialog(true);
        return;
      }

      try {
        console.log(
          'Attempting to connect with code:',
          authorizationCode.substring(0, 10) + '...'
        );

        const result = await connectGoogleCalendar({
          authorizationCode,
        }).unwrap();

        console.log('API response:', result);

        if (!mounted.current) {
          console.log('Navigating to integrations page due to error...');
          navigate(APP_ROUTES.SETTINGS.INTEGRATIONS, {
            state: { googleCalendarConnectError: true },
            replace: true,
          });
        }

        console.log('Google Calendar connection successful');

        toast({
          title: 'Google Calendar connected',
          description: 'Now you can invite Piloteer to your scheduled meetings',
          variant: 'success',
        });

        setTimeout(() => {
          if (!mounted.current) return;
          console.log('Navigating to meetings page...');
          navigate(APP_ROUTES.MEETINGS.MAIN, {
            state: { openCalendarModal: true },
            replace: true,
          });
        }, 500);
      } catch (error: unknown) {
        const err = error as {
          status?: number;
          data?: { message?: string };
          message?: string;
          stack?: string;
        };
        console.error('Detailed error:', {
          error,
          status: err.status,
          data: err.data,
          message: err.message,
          stack: err.stack,
        });

        if (!mounted.current) {
          console.log('Navigating to integrations page due to error...');
          navigate(APP_ROUTES.SETTINGS.INTEGRATIONS, {
            state: { googleCalendarConnectError: true },
            replace: true,
          });
        }

        // Force navigation regardless of error type
        toast({
          title: 'Failed to connect Google Calendar',
          description: 'Please try again or contact support',
          variant: 'destructive',
        });

        // Immediate navigation
        navigate(APP_ROUTES.SETTINGS.INTEGRATIONS, {
          state: {
            googleCalendarConnectError: true,
            errorDetails: err.data?.message || err.message,
          },
          replace: true,
        });
      } finally {
        isConnecting.current = false;
      }
    },
    [connectGoogleCalendar, navigate, toast, searchParams]
  );

  useEffect(() => {
    const authorizationCode = searchParams.get('code');

    if (authorizationCode) {
      handleConnectGoogleCalendar(authorizationCode);
    } else {
      navigate(APP_ROUTES.SETTINGS.INTEGRATIONS);
    }

    return () => {
      mounted.current = false;
    };
  }, [searchParams, handleConnectGoogleCalendar, navigate]);

  return (
    <>
      <Dialog
        open={showPermissionDialog}
        onOpenChange={setShowPermissionDialog}
      >
        <DialogContent className='sm:max-w-[425px]'>
          <DialogHeader>
            <DialogTitle>Calendar Access Required</DialogTitle>
            <DialogDescription>
              Please check the box to allow Piloteer to access your Google
              Calendar.
            </DialogDescription>
          </DialogHeader>
          <div className='my-4'>
            <img
              src={calendarPermissionImage}
              alt='Google Calendar Permission Checkbox'
              className='w-full rounded-lg border border-border'
            />
          </div>
          <DialogFooter>
            <Button onClick={handleTryAgain}>Try Again</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <div className='flex items-center justify-center h-screen'>
        <div className='text-center'>
          <Spinner />
          <p className='mt-4 text-xl font-medium'>
            Connecting your Google Calendar
          </p>
        </div>
      </div>
    </>
  );
};

export default GoogleCalendarOAuthCallback;
